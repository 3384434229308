import './App.css';
import { Container, Row, Col } from 'reactstrap';
import NavBar from './components/navbar';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './pages/home';
import Staking from './pages/staking';

function App() {
  return (
    <Router>
      <Container className="mb-3 mb-lg-5">
        <Row id="cc-header" className="mb-3 py-3 mb-md-5 py-md-5 py-lg-6 mb-lg-6 align-items-center">
          <div class="col-5 col-md-4">
            <img src={"./img/logo.png"} class="w-75 h-auto" alt="CryptoCart Staking" />
          </div>
          <div class="col-7 col-md-8 d-flex justify-content-end">
            <a href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x612e1726435fe38dd49a0b35b4065b56f49c8f11" class="buy-cta" target="_blank" rel="noreferrer">BUY CC Token</a>
          </div>
        </Row>
        <Row>
          <Col>
            <Switch>
              <Route path="/">
                <Staking />
              </Route>
            </Switch>
          </Col>
        </Row>
      </Container>
      <div id="cc-footer">
      <Container className="py-5 p-lg-5 text-center">
        <Row>
          <Col md="3" className="cc-footer-logo">
          <a href="https://giftcards.cryptocart.cc/" target="_blank" rel="noreferrer"><img src={"./img/footer-logo-gc.png"} class="cc-logo h-auto px-5 px-md-2 px-lg-3" alt="CryptoCart Giftcards" /></a>
          </Col>
          <Col md="3" className="cc-footer-logo">
          <a href="https://marketplace.cryptocart.cc/" target="_blank" rel="noreferrer"><img src={"./img/footer-logo-market.png"} class="cc-logo h-auto px-5 px-md-2 px-lg-3" alt="CryptoCart Marketplace" /></a>
          </Col>
          <Col md="3" className="cc-footer-logo">
          <a href="https://staking.cryptocart.cc/"><img src={"./img/footer-logo-staking.png"} class="cc-logo h-auto px-5 px-md-2 px-lg-3" alt="CryptoCart Staking" /></a>
          </Col>
          <Col md="3" className="cc-footer-logo">
          <a href="https://multibridge.cryptocart.cc/" target="_blank" rel="noreferrer"><img src={"./img/footer-logo-bridge.png"} class="cc-logo h-auto px-5 px-md-2 px-lg-3" alt="CryptoCart Multi-Bridge" /></a>
          </Col>
        </Row>
      </Container>
      </div>
      <div id="cc-footermenu">
      <Container className="py-5 p-lg-5 p-lg-5">
        <Row className="py-md-5 p-lg-5">
          <Col md="3">
          <a href="https://cryptocart.cc/" target="_blank" rel="noreferrer"><img src={"./img/footer-logo.png"} class="footer-logo h-auto mb-5" alt="CryptoCart" /></a>
          <p class="mb-0 small">&copy; Copyright 2021 Cryptocart.</p>
          <p class="small">All rights reserved</p>
          </Col>
          <Col md="3">
            <ul>
              <li><strong>Here to help</strong></li>
              <li><a href="mailto:contact@cryptocart.cc">contact@cryptocart.cc</a></li>
            </ul>
            <ul class="mb-3 mb-md-0">
              <li><strong>Policy</strong></li>
              <li><a href="https://cryptocart.cc/privacy-policy/" target="_blank" rel="noreferrer">Privacy & cookies policy</a></li>
              <li><a href="https://cryptocart.cc/terms-of-use/" target="_blank" rel="noreferrer">General terms & conditions</a></li>
              <li><a href="https://cryptocart.cc/terms-of-service/" target="_blank" rel="noreferrer">Product terms & conditions</a></li>
            </ul>
          </Col>
          <Col md="3">
            <ul>
              <li><strong>Join Community</strong></li>
              <li><a href="https://twitter.com/cryptocartcc" target="_blank" rel="noreferrer"><img src="./img/ft-twitter.svg" alt="Twitter" /> @CryptoCartCC</a></li>
              <li><a href="https://t.me/cryptocartcc" target="_blank" rel="noreferrer"><img src="./img/ft-telegram.svg" alt="Telegram" /> CryptoCart Official</a></li>
            </ul>
            <ul>
              <li><strong>Explore CC Token</strong></li>
              <li><a href="https://www.dextools.io/app/uniswap/pair-explorer/0xbebc3520759bf96f1fe400f792271c325eaa9c49" target="_blank" rel="noreferrer"><img src="./img/ft-dextools.svg" alt="Dextools" /> Dextools</a></li>
            </ul>
          </Col>
          <Col md="3">
            <ul>
              <li><strong>Buy CC Token</strong></li>
              <li><a href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x612e1726435fe38dd49a0b35b4065b56f49c8f11" target="_blank" rel="noreferrer" class="cc-ft ft-uniswap"><img src="./img/ft-uniswap.svg" alt="Uniswap" /> UNISWAP</a></li>
            </ul>
          </Col>
        </Row>
      </Container>
      </div>
    </Router>
  );
}

export default App;
